import { useParams, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ReactPlayer from 'react-player/vimeo';
import BackGround from '../BackGround/BackGround';
import s from "./SubPage.module.css";

const SubPage = ({setNavigation}) => {
  const { id } = useParams(); // Access the id parameter
//<p>{content[id]?.text || 'No content available'}</p>

  const [data, setData] = useState(null);
 let navigation;
  useEffect(() => {
    const loadData = async () => {
      try {
        // Dynamically import the JSON file based on id
        const jsonData = await import(`./data/${id}.json`);
        setData(jsonData.default); // `default` is needed when importing JSON dynamically
      } catch (error) {
        console.error("Error loading JSON file:", error);
      }
    };

    loadData();
  }, [id]);

  if (!data) return <div>Loading...</div>;
  if (id === "datar" || id === "personendepot" || id === "ulkus"){
    navigation = "main";
  }else{
    navigation = "media";
  }
  return (
    <div className={s.parent}>
      <BackGround page ={"other"}/>
      
      <Link to = "/" onClick={() => setNavigation(navigation)}>
      <svg viewBox="-1vw -1vw 20vw 10vw"  width="30vw" height="15vw" fill="none" xmlns="http://www.w3.org/2000/svg" className={s.arrow}  >
        <path d="M0.585785 13.5858C 5 14.3668 5 15.6332 0.585785 16.4142L13.3137 29.1421C14.0948 29.9232 15.3611 29.9232 16.1421 29.1421C16.9232 28.3611 16.9232 27.0948 16.1421 26.3137L4.82843 15L16.1421 3.68629C16.9232 2.90524 16.9232 1.63891 16.1421 0.857864C15.3611 0.0768156 14.0948 0.0768156 13.3137 0.857864L0.585785 13.5858ZM53 13L2 13V17L53 17V13Z" stroke="#55433B" stroke-width="5"/>
      </svg>
      </Link>

      <div className={s.gridparent}>
        <div className={s.meta_block}>
          <p className={s.title}>{data.title} <br/> {data.year}</p>
          <div className={s.metadata}>
          {Object.entries(data.metadata).map(([key, value]) => (
            <p key={key}>
            <span className={s.bold}>{key}</span>  <br /> <span className={s.light}>{value}</span>
            </p>
          ))}
          </div>
        </div>

          <div className={s.headerimg}>
            <img src={data.headerimg} alt='user study' />
          </div>
          <div className={s.meta_block_mobile}>
          <p className={s.title}>{data.title} <br/> {data.year}</p>
          <div className={s.metadata}>
          {Object.entries(data.metadata).map(([key, value]) => (
            <p key={key}>
            <span className={s.bold}>{key}</span>  <br /> <span className={s.light}>{value}</span>
            </p>
          ))}
          </div>
        </div>
      </div>
      
      <div className={s.textblock}>       
        <p className={s.bold}>{data.subtitle}</p>
        <p className={s.light} dangerouslySetInnerHTML={{ __html: data.abstract }}></p>
      </div>

      {(id === "esigns") && (
        <div>
        <ReactPlayer
          url="https://vimeo.com/321677268"
          controls={true}
          width="50%"
          height="30vw"
          loop={true}
          className={s.videoblock}
        />
        <img className={s.gif_esigns_1} src="/images/diseas_gif.gif" alt="gif diseas" />
        <img className={s.gif_esigns_2} src="/images/organ_gif.gif" alt="gif organ" />
        </div>
      )}
      {(id === "ulkus") && (
        <img className={s.gif_ulkus} src="/images/ulkus_gif.gif" alt="gif experiment" />
      )}
      {(id === "artidance") && (
        <ReactPlayer
        url="https://vimeo.com/1026332718"
        controls={true}
        width="70%"
        height="40vw"
        loop={true}
        className={s.videoblock}
      />
      )}
      {(id === "wave") && (
        <div>
          <img className={s.gif_wave_1} src="/images/wave3_gif1.gif" alt="gif wave1" />
          <img className={s.gif_wave_2} src="/images/wave3_gif2.gif" alt="gif wave2" />
          <img className={s.gif_wave_4} src="/images/wave4_gif.gif" alt="gif zotrop" />
        </div>
      )}
      {(id === "form") && (
        <div>
          <img className={s.gif_form_1} src="/images/form_gif1.gif" alt="gif stopmotion1" />
          <img className={s.gif_form_2} src="/images/form_gif2.gif" alt="gif stopmotion2" />
          <ReactPlayer
        url="https://vimeo.com/372281555"
        controls={true}
        width="94%"
        height="25vw"
        loop={true}
        className={s.video_form}
      />
        </div>
      )}

      {Object.entries(data.images).map(([key, value]) => (
        <div>
            {(key in data) ? (
              <div className={s.flex_container}>
              <img className={s.imgblock2} src={value} alt={key} key={key} />
              <div className={s.textblock2}>       
                <p className={s.bold}>{key}</p>
                <p className={s.light} dangerouslySetInnerHTML={{ __html: data[key] }}></p>
              </div>
              </div>
              ) : (
                <div>
                <img className={s.imgblock} src={value} alt={key} key={key} />
                <div className={s.textblock}>
                <p className={s.figure_text} dangerouslySetInnerHTML={{ __html: data[key + "description"] }}></p>
                </div>
                </div>
              )}
        </div>
          ))}
      

    </div>
  );
};

export default SubPage;
