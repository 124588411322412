import NavBar from "../NavBar/NavBar";
import Main from "../Galery/Main";
import Media from "../Galery/Media";
import Animation from "../Galery/Animation";
import About from "../Galery/About";
import BackGround from "../BackGround/BackGround";
import s from './Home.module.css';
import { useState } from 'react';

function Home({data}) {
  const [navigation, setNavigation] = useState(data);
  const handleDataFromChild = (d) => {
    setNavigation(d);
};
  return (
    <div className={s.parent}>
       <BackGround page={navigation}/>
      <NavBar sendData={handleDataFromChild} navigation={data} />
      {(navigation === "main") && (
        <Main />
      )}
      {(navigation === "media") && (
        <Media />
      )}
      {(navigation === "animation") && (
        <Animation />
      )}
      {(navigation === "about") && (
        <About />
      )}
      
    </div>
  );
}

export default Home;