import s from "./About.module.css";
import about from "../images/about.jpg";

const About = () =>(
    <div className={s.scroll_container}>
    <div className={s.container}>
    <img className={s.img_container} src={about} alt="profile"/>
    <p className={s.text_container}>Hello, I’m Lilian Lopez, a designer and developer passionate about creating thoughtful, user-centered experiences. With a background in Interaction Design and Computer Science, I enjoy working at the intersection of design, art, technology, and human interaction.<br/><br/>
    My journey has encompassed diverse projects, from developing an AR learning application for high school students to designing interactive visuals for dance performances. Along the way, I’ve collaborated with peers from various disciplines, often bridging creative and technical roles.<br/>
    I’m inspired by opportunities to make technology more accessible and engaging—whether through interactive prototyping, user research, or developing applications that address real-world challenges. Additionally, I am motivated by a global perspective, shaped by studying and volunteering in multicultural settings that continue to influence my approach to design.
    </p>
    </div>
    <div className={s.container2}>
    <p className={s.title}>Education</p>
    <p className={s.subtitle}><span className={s.text}>2024 </span>ETH Zurich, Zurich — BA Computer Science </p>
    <p className={s.text}>Focused on the fundamentals of mathematics as well as of systematic programming and its fundamental concepts.</p>
    <p className={s.subtitle}><span className={s.text}>2021 </span>Zurich University of the Arts, Zurich — BA Interaction Design</p>
    <p className={s.text}>Exploring the interaction between people, environments and technology - from a social, experiential and ecological perspective.</p>
    <p className={s.subtitle}><span className={s.text}>2019 </span>Universidad Nacional de las Artes, Buenos Aires — Exchange</p>
    <p className={s.text}>Took classes from the multimedia and traditional arts curriculum.</p>
    <p className={s.subtitle}><span className={s.text}>2017 </span>First Floor, Zurich — Art and Design Propedeutics</p>
    <p className={s.subtitle}><span className={s.text}>2016 </span>Gymnasium MNG, Zurich — High school diploma</p>
    <p className={s.title}>Experience</p>
    <p className={s.subtitle}><span className={s.text}>Summer/Fall 2024 </span>ETH Zurich, Zurich — Research Assistant</p>
    <p className={s.text}>Project lead for developing an AR application at the Programming, Education, and Computer-Human Interaction Lab under Prof. April Wang. Gained hands-on experience in Unity software development, UX design, project management, user study conduction, quantitative data analysis, and scientific writing.</p>
    <p className={s.subtitle}><span className={s.text}>Spring/Summer 2020 </span>ProSieben Media Company, Munic — Internship</p>
    <p className={s.text}>Designed and developed digital platform advertisements, using Adobe Photoshop and HTML/CSS extensively for animation and graphic design.</p>
    <p className={s.title}>Volunteer</p>
    <p className={s.subtitle}><span className={s.text}>2024 </span>FAIRPLAY Gym, Zurich — Children's Kickboxing Coach</p>
    <p className={s.text}>Giving classes in Kickboxing for primary school children at my local boxing club.</p>
    <p className={s.subtitle}><span className={s.text}>Summer 2018 </span>Selva Bananito Lodge, Costa Rica — Hospitality and Reforestation</p>
    <p className={s.title}>Public Presentation</p>
    <p className={s.subtitle}><span className={s.text}>2024 </span>VL/HCC Conference - Poster Presentation for datAR</p>
    <p className={s.subtitle}><span className={s.text}>2021 </span>ARS Electronica Linz - "Personendepot" part of Infinite Nows</p>
    <p className={s.subtitle}><span className={s.text}>2018 </span>Zauberwald Lenzerheide - "Wind Energy" Cooperation with EWZ</p>
    <p className={s.subtitle}><span className={s.text}>2018 </span>ZHdK Highlights - "Embodied Signs" Colaboration with Music Composition MA</p>
    </div>
    </div>
);

export default About;