import s from "./Galery.module.css";
import ReactPlayer from 'react-player/vimeo';

const Animation = () =>(
    <div className={s.scroll_container}>
    <div className={s.parent}>
        <div className= {s.column}>
        <div className={` ${s.group1} ${s.video_card}`}>
        <ReactPlayer
          url="https://vimeo.com/372281555"
          controls={true}
          width="100%"
          loop={true}
        /></div>
        <div className={s.text_block_mobile}>
            <p className={s.title2}>Cubes 2019</p>
            <p className={s.text}>A Stopmotion Animation</p>
            <p className={s.subtitle2}>Animation/Sound Design: <span className={s.text}>Lilian Lopez</span></p>
          </div>
        <div className={` ${s.group2} ${s.video_card}`}>
        <ReactPlayer
          url="https://vimeo.com/463801129"
          controls={true}
          width="100%"
          loop={true}
        /></div>
        <div className={s.text_block_mobile}>
            <p className={s.title2}>Delphi 2019</p>
            <p className={s.text}>A Service Design Concept Video for a Recipe Platform</p>
            <p className={s.subtitle2}>Animation/Illustration: <span className={s.text}>Lilian Lopez, Claudia Buck</span></p>
            <p className={s.subtitle2}>Sound Design: <span className={s.text}>Marcial Koch</span></p>
          </div>
        <div className={` ${s.group2} ${s.video_card}`}>
        <ReactPlayer
          url="https://vimeo.com/1030953875?share=copy#t=0"
          controls={true}
          width="100%"
          loop={true}
        /></div>
        <div className={s.text_block_mobile}>
            <p className={s.title2}>Personendepot 2021</p>
            <p className={s.text}>Teaser for an Alternative Social Media Platform</p>
            <p className={s.subtitle2}>Animation: <span className={s.text}>Lilian Lopez</span></p>
          </div>
        </div> 
        <div className= {s.column2}>
            <div className={s.text_block}>
            <p className={s.title2}>Cubes <br/> 2019</p>
            <p className={s.text}>A Stopmotion Animation</p>
            <p className={s.subtitle2}>Animation/Sound Design<br/><span className={s.text}>Lilian Lopez</span></p>
            </div>
            <div className={s.text_block}>
            <p className={s.title2}>Delphi <br/> 2019</p>
            <p className={s.text}>A Service Design Concept Video for a Recipe Platform</p>
            <p className={s.subtitle2}>Animation/Illustration<br/><span className={s.text}>Lilian Lopez, Claudia Buck</span></p>
            <p className={s.subtitle2}>Sound Design<br/><span className={s.text}>Marcial Koch</span></p>
            </div>
            <div className={s.text_block}>
            <p className={s.title2}>Personendepot <br/> 2021</p>
            <p className={s.text}>Teaser for an Alternative Social Media Platform</p>
            <p className={s.subtitle2}>Animation<br/><span className={s.text}>Lilian Lopez</span></p>
            </div>
        </div>         
    </div>
    </div>
)

export default Animation;